import React from 'react';
import Hero from '../components/Hero.js';
import MenuHighlights from '../components/MenuHighlights.js';

const Home = () => (
    <>
        <Hero />
        <MenuHighlights />
    </>
);

export default Home;
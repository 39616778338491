import React, { useState, useEffect } from 'react';
import styles from './MenuItemModal.module.css';
import Popup from './Popup';
import { DEBUG_MODE } from '../config.js';

const MenuItemModal = ({ item, onClose, onAddToCart, isDevelopment }) => {
    const [customizations, setCustomizations] = useState({});
    const [totalPrice, setTotalPrice] = useState(item.basePrice);
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        calculateTotalPrice();
    }, [customizations]);

    const calculateTotalPrice = () => {
        let price = item.basePrice;
        Object.entries(customizations).forEach(([header, value]) => {
            const customization = item.customizations.find(c => c.header === header);
            if (customization) {
                if (customization.type === 'multiple_choice') {
                    const option = customization.options.find(o => o.name === value);
                    if (option) price += option.price;
                } else if (customization.type === 'multiple_select') {
                    value.forEach(selectedOption => {
                        const option = customization.options.find(o => o.name === selectedOption);
                        if (option) price += option.price;
                    });
                }
            }
        });
        setTotalPrice(price);
    };

    const handleCustomization = (header, value, type) => {
        if (type === 'multiple_select') {
            setCustomizations(prev => {
                const currentSelection = prev[header] || [];
                if (currentSelection.includes(value)) {
                    return { ...prev, [header]: currentSelection.filter(item => item !== value) };
                } else {
                    return { ...prev, [header]: [...currentSelection, value] };
                }
            });
        } else {
            setCustomizations(prev => ({ ...prev, [header]: value }));
        }
    };

    const renderCustomizationInput = (customization) => {
        switch (customization.type) {
            case 'text_entry':
                return (
                    <input
                        type="text"
                        placeholder={`Enter ${customization.header}`}
                        onChange={(e) => handleCustomization(customization.header, e.target.value, customization.type)}
                        className={styles.textInput}
                    />
                );
            case 'multiple_choice':
                return (
                    <select
                        onChange={(e) => handleCustomization(customization.header, e.target.value, customization.type)}
                        className={styles.selectInput}
                    >
                        <option value="">Select {customization.header}</option>
                        {customization.options.map(option => (
                            <option key={option.name} value={option.name}>
                                {option.name} {option.price > 0 ? `(+$${option.price.toFixed(2)})` : ''}
                            </option>
                        ))}
                    </select>
                );
            case 'multiple_select':
                return (
                    <div className={styles.checkboxGroup}>
                        {customization.options.map(option => (
                            <label key={option.name} className={styles.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    value={option.name}
                                    onChange={() => handleCustomization(customization.header, option.name, customization.type)}
                                    checked={customizations[customization.header]?.includes(option.name)}
                                />
                                {option.name} {option.price > 0 ? `(+$${option.price.toFixed(2)})` : ''}
                            </label>
                        ))}
                    </div>
                );
            default:
                return null;
        }
    };

    const handleAddToCart = () => {
        if (DEBUG_MODE) {
            setShowPopup(true);
        } else {
            onAddToCart(item, customizations, totalPrice);
        }

    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <button className={styles.closeButton} onClick={onClose}>&times;</button>
                {item.image && (
                    <div className={styles.modalImageContainer}>
                        <img src={item.image} alt={item.name} className={styles.modalItemImage} />
                    </div>
                )}
                <h2>{item.name}</h2>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                <p className={styles.price}>Total: ${totalPrice.toFixed(2)}</p>

                {item.customizations.map(customization => (
                    <div key={customization.header} className={styles.customization}>
                        <h3>{customization.header}</h3>
                        {renderCustomizationInput(customization)}
                    </div>
                ))}

                {(isDevelopment || !isDevelopment) && (
                    <button className={styles.addToCartButton} onClick={handleAddToCart}>
                        Add to Cart - ${totalPrice.toFixed(2)}
                    </button>
                )}
            </div>
            {showPopup && (
                <Popup
                    message="This feature is coming soon! If you'd like to place a To Go order, please call (816) 492-6055"
                    onClose={closePopup}
                />
            )}
        </div>

    );
};

export default MenuItemModal;
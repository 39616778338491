import React, { useState } from 'react';
import { Phone, Utensils, Mail, MapPin, Calendar } from 'lucide-react';
import styles from './Contact.module.css';

const Contact = () => {
    const [copiedText, setCopiedText] = useState('');

    const handlePhoneClick = () => {
        window.location.href = 'tel:+18164926055';
    };

    const handleEmailClick = (email) => {
        navigator.clipboard.writeText(email).then(() => {
            setCopiedText(email);
            setTimeout(() => setCopiedText(''), 3000);
        });
    };

    const handleLocationClick = () => {
        const address = encodeURIComponent("6559 N Oak Trafficway, Gladstone, MO 64118");
        const appleMapsUrl = `maps://maps.apple.com/?q=${address}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            window.location.href = appleMapsUrl;
        } else {
            window.open(googleMapsUrl, '_blank');
        }
    };

    return (
        <div className={styles.contactWrapper}>
            <div className={styles.contact}>
                <h1>Contact Us</h1>
                <p className={styles.intro}>
                    We'd love to hear from you! Whether you have a question about our menu, want to book a table, or are interested in our catering services, we're here to help.
                </p>
                <div className={styles.contactInfo}>
                    <div className={styles.infoItem} onClick={handlePhoneClick}>
                        <Phone className={styles.icon} />
                        <div>
                            <h2>Phone</h2>
                            <p>(816) 492-6055</p>
                            <p className={styles.reservationNote}>
                                This number may also be used to place orders.
                            </p>
                        </div>
                    </div>
                    <div className={styles.infoItem} onClick={() => handleEmailClick('photuoikc@gmail.com')}>
                        <Utensils className={styles.icon} />
                        <div>
                            <h2>Catering</h2>
                            <p>photuoikc@gmail.com</p>
                        </div>
                    </div>
                    <div className={styles.infoItem} onClick={() => handleEmailClick('photuoikc@gmail.com')}>
                        <Mail className={styles.icon} />
                        <div>
                            <h2>Email</h2>
                            <p>photuoikc@gmail.com</p>
                        </div>
                    </div>
                    <div className={styles.infoItem} onClick={handleLocationClick}>
                        <MapPin className={styles.icon} />
                        <div>
                            <h2>Address</h2>
                            <p>6559 N Oak Trafficway, Gladstone, MO 64118</p>
                        </div>
                    </div>
                    <div className={styles.infoItem} onClick={handlePhoneClick}>
                        <Calendar className={styles.icon} />
                        <div>
                            <h2>Reservations</h2>
                            <p>Call (816) 492-6055 to make a reservation</p>
                            <p className={styles.reservationNote}>
                                For parties larger than 20 people, please make your reservation at least 3 days in advance.
                            </p>
                        </div>
                    </div>
                </div>
                {copiedText && (
                    <div className={styles.notification}>
                        Copied to clipboard: {copiedText}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;
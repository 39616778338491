import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingBag, Menu, X } from 'lucide-react';
import styles from './Header.module.css';
import Popup from './Popup';
import { DEBUG_MODE } from '../config.js';

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const dropdownRef = useRef(null);
    const mobileNavRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        const handleClickOutside = (event) => {
            if (mobileNavRef.current && !mobileNavRef.current.contains(event.target) && isMenuOpen) {
                setIsMenuOpen(false);
            }
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setIsMenuOpen(false);
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            window.removeEventListener('resize', checkMobile);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isMenuOpen]);

    const openMaps = () => {
        const address = encodeURIComponent("6559 N Oak Trafficway, Gladstone, MO 64118");
        const appleMapsUrl = `maps://maps.apple.com/?q=${address}`;
        const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            window.location.href = appleMapsUrl;
        } else {
            window.open(googleMapsUrl, '_blank');
        }
    };

    const handleOrderNowClick = () => {
        if (DEBUG_MODE) {
            setShowPopup(true);
        } else if (isMobile) {
            setShowDropdown(!showDropdown);
        } else {
            setShowDropdown(true);
        }
    };

    const handleToGoClick = () => {
        if (DEBUG_MODE) {
            setShowPopup(true);
        } else {
            navigate('/menu');
            setShowDropdown(false);
            setIsMenuOpen(false);
        }
    };

    const handleDeliveryClick = () => {

        window.open('https://www.ubereats.com/store/pho-tuoi/VnU4Pag3W523_o10wGBMQw', '_blank');
        setShowDropdown(false);
        setIsMenuOpen(false);

    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const NavLinks = () => (
        <>
            <li><Link to="/menu" onClick={() => setIsMenuOpen(false)}>Menu</Link></li>
            <li><Link to="/about" onClick={() => setIsMenuOpen(false)}>About</Link></li>
            <li><Link to="/contact" onClick={() => setIsMenuOpen(false)}>Contact</Link></li>
            <li
                className={styles.orderNowContainer}
                ref={dropdownRef}
                onMouseEnter={() => !isMobile && setShowDropdown(true)}
                onMouseLeave={() => !isMobile && setShowDropdown(false)}
            >
                <button
                    onClick={handleOrderNowClick}
                    className={styles.orderNowButton}
                >
                    Order Now
                </button>
                <div className={`${styles.dropdown} ${showDropdown ? styles.show : ''}`}>
                    <button onClick={handleToGoClick} className={styles.dropdownItem}>To Go</button>
                    <button onClick={handleDeliveryClick} className={styles.dropdownItem}>Delivery</button>
                </div>
            </li>
            {isMobile && (
                <>
                    <li>
                        <button onClick={handleDeliveryClick} className={styles.dropdownItem}>Delivery</button>
                    </li>
                    <li>
                        <button onClick={openMaps} className={styles.findRestaurantButton}>
                            Find Restaurant
                        </button>
                    </li>
                    <li className={styles.cartIconMobile}>
                        <ShoppingBag size={24} />
                        <span>Cart</span>
                    </li>
                </>
            )}
        </>
    );

    return (
        <header className={styles.header}>
            <div className={styles.logoContainer}>
                <Link to="/">
                    <img src="/logo.png" alt="Pho Tuoi Logo" className={styles.logo} />
                </Link>
            </div>
            {isMobile ? (
                <>
                    <button className={styles.menuButton} onClick={toggleMenu}>
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                    <nav ref={mobileNavRef} className={`${styles.mobileNav} ${isMenuOpen ? styles.open : ''}`}>
                        <ul className={styles.navList}>
                            <NavLinks />
                        </ul>
                    </nav>
                </>
            ) : (
                <>
                    <nav className={styles.nav}>
                        <ul className={styles.navList}>
                            <NavLinks />
                        </ul>
                    </nav>
                    <div className={styles.headerActions}>
                        <button onClick={openMaps} className={styles.findRestaurantButton}>
                            Find Restaurant
                        </button>
                        <div className={styles.cartIcon} onClick={DEBUG_MODE ? () => setShowPopup(true) : null}>
                            <ShoppingBag size={24} />
                        </div>
                    </div>
                </>
            )}
            {showPopup && (
                <Popup
                    message="This feature is coming soon! If you'd like to place a To Go order, please call (816) 492-6055"
                    onClose={closePopup}
                />
            )}
        </header>
    );
};

export default Header;
import React from 'react';
import styles from './MenuItem.module.css';

const MenuItem = ({ item, onClick }) => {
    return (
        <div className={`${styles.menuItem} ${!item.image ? styles.noImage : ''}`} onClick={onClick}>
            {item.image ? (
                <div className={styles.imageContainer}>
                    <img src={item.image} alt={item.name} className={styles.itemImage} />
                </div>
            ) : (
                <div className={styles.placeholderImage}></div>
            )}
            <div className={styles.itemInfo}>
                <h3>{item.name}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                <p className={styles.price}>From ${item.basePrice.toFixed(2)}</p>
            </div>
        </div>
    );
};

export default MenuItem;
import React, { useState, useEffect } from 'react';
import styles from './About.module.css';

const About = () => {
    const [currentImage, setCurrentImage] = useState(1);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                setCurrentImage((prev) => (prev % 6) + 1);
                setFadeOut(false);
            }, 500); // Half of the transition time
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.aboutWrapper}>
            <div className={styles.about}>
                <div className={styles.blurb}>
                    <h1>About Pho <span className={styles.highlight}>Tuoi</span></h1>
                    <p>
                        Welcome to <span className={styles.highlight}>Pho Tuoi</span>, where we serve
                        <span className={styles.highlight}> authentic Vietnamese cuisine</span> with a
                        focus on our signature <span className={styles.highlight}>pho</span>. Our recipes have
                        been passed down through <span className={styles.highlight}>generations</span>,
                        ensuring a <span className={styles.highlight}>genuine and delicious</span> dining
                        experience. We take pride in using only the <span className={styles.highlight}>freshest
                            ingredients</span> to create <span className={styles.highlight}>flavorful broths</span> and
                        <span className={styles.highlight}> perfectly balanced dishes</span> that capture the
                        essence of Vietnamese culinary tradition.
                    </p>
                </div>
                <div className={styles.gallery}>
                    <img
                        src={`/gallery-${currentImage}.jpg`}
                        alt={`Restaurant interior ${currentImage}`}
                        className={`${styles.galleryImage} ${fadeOut ? styles.fadeOut : ''}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default About;
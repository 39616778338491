import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import styles from './MenuHighlights.module.css';
import { DEBUG_MODE } from '../config.js';
import Popup from './Popup';

const MenuHighlights = () => {
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };
    const handleOrderButtonClick = () => {
        if (DEBUG_MODE) {
            setShowPopup(true)

        } else {
            ///.
        }
    }

    return (
        <section className={styles.menuHighlights}>
            <h2 className={styles.title}>Menu Highlights</h2>
            <div className={styles.highlightsGrid}>
                <div className={styles.highlightItem}>
                    <img src="/banhmithitnuong.jpeg" alt="Authentic Banh Mi" className={styles.highlightImage} />
                    <div className={styles.highlightContent} >
                        <h3 className={styles.highlightTitle}>AUTHENTIC BANH MI</h3>
                        <button onClick={handleOrderButtonClick} className={styles.orderButton}>ORDER NOW</button>
                    </div>
                </div>
                <div className={styles.highlightItem}>
                    <img src="/egg-rolls.jpeg" alt="Appetizers" className={styles.highlightImage} />
                    <div className={styles.highlightContent}>
                        <h3 className={styles.highlightTitle}>APPETIZERS</h3>
                        <button onClick={handleOrderButtonClick} className={styles.orderButton}>ORDER NOW</button>
                    </div>
                </div>
                <div className={styles.highlightItem}>
                    <img src="/comsuon.jpeg" alt="Classic Entrees" className={styles.highlightImage} />
                    <div className={styles.highlightContent}>
                        <h3 className={styles.highlightTitle}>CLASSIC ENTREES</h3>
                        <button onClick={handleOrderButtonClick} className={styles.orderButton}>ORDER NOW</button>
                    </div>
                </div>
                <div className={styles.highlightItem}>
                    <img src="/chicken-pho.jpeg" alt="Create Your Own Pho" className={styles.highlightImage} />
                    <div className={styles.highlightContent}>
                        <h3 className={styles.highlightTitle}>CREATE YOUR OWN PHO</h3>
                        <button onClick={handleOrderButtonClick} className={styles.orderButton}>ORDER NOW</button>
                    </div>
                </div>
            </div>
            {showPopup && (
                <Popup
                    message="This feature is coming soon! If you'd like to place a To Go order, please call (816) 492-6055"
                    onClose={closePopup}
                />
            )}
        </section>
    );
}

export default MenuHighlights;
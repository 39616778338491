import React from 'react';
import styles from './Popup.module.css';

const Popup = ({ message, onClose }) => (
    <div className={styles.popupOverlay}>
        <div className={styles.popup}>
            <p>{message}</p>
            <button onClick={onClose}>Close</button>
        </div>
    </div>
);

export default Popup;
import React, { useState } from 'react';
import styles from './Menu.module.css';
import MenuItem from '../components/MenuItem.js';
import MenuItemModal from '../components/MenuItemModal.js';
import menuData from '../menuData.js';


const Menu = ({ isDevelopment = true }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
    };

    const handleAddToCart = (item, customizations) => {
        const order = { ...item, customizations };
        console.log('Order:', JSON.stringify(order, null, 2));
        handleCloseModal();
    };

    return (
        <div className={styles.menuWrapper}>
            <div className={styles.menu}>
                {Object.entries(menuData).map(([category, items]) => (
                    <div key={category} className={styles.category}>
                        <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
                        <div className={styles.grid}>
                            {items.map(item => (
                                <div key={item.id} className={styles.gridItem}>
                                    <MenuItem item={item} onClick={() => handleItemClick(item)} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {selectedItem && (
                <MenuItemModal
                    item={selectedItem}
                    onClose={handleCloseModal}
                    onAddToCart={handleAddToCart}
                    isDevelopment={isDevelopment}
                />
            )}
        </div>
    );
};

export default Menu;
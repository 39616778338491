const menuData = {
    appetizers: [
        {
            id: 1,
            name: 'Shrimp & Pork Spring Roll (Goi Cuon Tom Thit)',
            basePrice: 6.99,
            description: 'Fresh vegetables, shrimp, and pork wrapped in rice paper',
            image: '/spring-rolls.jpeg',
            customizations: []
        },
        {
            id: 2,
            name: 'Steamed Pork Pun (Banh Bao)',
            basePrice: 5.99,
            description: 'Flavorful bun filled',
            image: '/banhbao.jpeg',
            customizations: []
        },
        {
            id: 3,
            name: 'Egg Rolls (Cha Gio) (4 pcs)',
            basePrice: 6.99,
            description: 'Crispy fried rolls with pork and vegetables',
            image: '/egg-rolls.jpeg',
            customizations: []
        },
        {
            id: 4,
            name: 'Crab Rangoon (Banh Cua Chien)',
            basePrice: 7.49,
            image: '/crabrangoon.jpeg',
            description: 'Crispy wontons filled with crab and cream cheese',
            customizations: []
        },
        {
            id: 5,
            name: 'Grilled Pork Roll (Cuon Thit Nuong)',
            basePrice: 6.99,
            image: '/grilled-pork-roll.jpeg',
            description: 'Grilled pork with fresh vegetables wrapped in rice paper',
            customizations: []
        },
        {
            id: 6,
            name: 'Sweet Potato Fries with Shrimp',
            basePrice: 10.99,
            image: '/sweet-potato-fries.jpeg',
            description: 'Crispy sweet potato fries topped with shrimp',
            customizations: []
        },
        {
            id: 7,
            name: 'Vietnamese Sticky Rice (Xoi Man)',
            basePrice: 8.99,
            description: 'Sticky rice with a savory topping',
            customizations: []
        },
        {
            id: 8,
            name: 'Quail (Chim Cut)',
            basePrice: 11.99,
            description: 'Grilled or fried quail',
            customizations: []
        },
        {
            id: 9,
            name: 'Calamari (Muc Tam Bot Chien)',
            basePrice: 9.49,
            description: 'Crispy fried calamari with dipping sauce',
            customizations: []
        },
        {
            id: 10,
            name: 'Fish Balls & Beef Balls Fried (Ca/ Bo Vien Chien)',
            basePrice: 8.99,
            description: 'Fried fish balls and beef balls',
            customizations: []
        },
        {
            id: 11,
            name: 'French Fries/ Chicken Nuggets',
            basePrice: 7.49,
            description: 'Crispy french fries and chicken nuggets',
            customizations: []
        },
        {
            id: 12,
            name: 'Popcorn Chicken',
            basePrice: 7.49,
            description: 'Bite-sized fried chicken pieces',
            customizations: []
        }
    ],
    pho: [
        {
            id: 13,
            name: 'Beef Pho',
            basePrice: 16.99,
            description: 'Rice noodle soup with your choice of beef. <span style="color: red; font-style: italic; font-size: 13px">(Sliced Steak is served RAW and is cooked by the dishes broth.)</span>',
            image: '/beef-pho.jpeg',
            customizations: [
                {
                    header: 'Size',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Medium', price: 0 },
                        { name: 'Large', price: 3 }
                    ]
                },
                {
                    header: 'Noodle preparation',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Regular Pho Noodle', price: 0 },
                        { name: 'Egg noodle', price: 0 },
                        { name: 'Fresh noodle', price: 0.75 },
                    ]
                },
                {
                    header: 'Meat',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Sliced Steak Raw (Tai)', price: 0 },
                        { name: 'Brisket (Chin)', price: 0 },
                        { name: 'Beef Meatballs (Bo Vien)', price: 0 },
                        { name: 'Tripe (Sach)', price: 0 },
                        { name: 'Tendon (Gan)', price: 0 },
                        { name: 'Fat Brisket (Gau)', price: 0 },
                        { name: 'Flank (Nam)', price: 0 }
                    ]
                },
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Shrimp', price: 5.00 },
                        { name: 'Egg (1)', price: 2.50 },
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 14,
            name: 'Chicken Pho (White Meat)',
            basePrice: 16.99,
            description: 'Rice noodle soup with chicken',
            image: '/chicken-pho.jpeg',
            customizations: [
                {
                    header: 'Size',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Medium', price: 0 },
                        { name: 'Large', price: 3 }
                    ]
                },
                {
                    header: 'Noodle preparation',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Regular Pho Noodle', price: 0 },
                        { name: 'Egg noodle', price: 0 },
                        { name: 'Fresh noodle', price: 0.75 },
                    ]
                },
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Shrimp', price: 5.00 },
                        { name: 'Egg (1)', price: 2.50 },
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 15,
            name: 'Seafood Pho (Squid & Shrimp)',
            basePrice: 18.99,
            image: '/seafood-pho.jpeg',
            description: 'Rice noodle soup with squid and shrimp',
            customizations: [
                {
                    header: 'Size',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Medium', price: 0 },
                        { name: 'Large', price: 3 }
                    ]
                },
                {
                    header: 'Noodle preparation',
                    type: 'multiple_choice',
                    options: [
                        { name: 'Regular Pho Noodle', price: 0 },
                        { name: 'Egg noodle', price: 0 },
                        { name: 'Fresh noodle', price: 0.75 },
                    ]
                },
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Shrimp', price: 5.00 },
                        { name: 'Egg (1)', price: 2.50 },
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        }
    ],
    noodles: [
        {
            id: 16,
            name: 'Spicy Beef & Pork Noodles (Bun Bo Hue)',
            basePrice: 16.99,
            image: '/bunbohue.jpeg',
            description: 'Beef, tendon, pork blood, pork feet, rice noodle, lemongrass, and chili',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 17,
            name: 'Wonton & Egg Noodles (Mi Hoanh Thanh)',
            basePrice: 16.99,
            image: '/mihoanh.jpeg',
            description: 'Egg noodles with wontons',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 18,
            name: 'Pork & Prawn Noodles (Hu Tieu Nam Vang)',
            basePrice: 16.99,
            image: '/hutieu.jpeg',
            description: 'Rice noodle, pork, shrimp, and pig heart',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 19,
            name: 'Grill Pork & Rice Vermicelli (Bun Thit Nuong Cha Gio)',
            basePrice: 16.99,
            description: 'Vermicelli, grilled pork, egg roll, and veggies with fish sauce',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 20,
            name: 'Crab Tapioca Noodles (Banh Canh Cua)',
            basePrice: 17.99,
            description: 'Tapioca noodle, pork, shrimp, crab meat, and fish cake',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 21,
            name: 'Stir-Fried Noodles with Chicken (Mi Xao Ga)',
            basePrice: 16.99,
            description: 'Stir-fried noodles with chicken',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 22,
            name: 'Stir-Fried Vegan Noodles (Mi Xao Chay)',
            basePrice: 14.99,
            description: 'Stir-fried noodles with mixed vegetables',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 23,
            name: 'Combo Stir-Fried Noodles (Mi Xao Dac Biet)',
            basePrice: 18.99,
            description: 'Egg Noodles, Beef, Chicken, Shrimp, and Vegetables',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 24,
            name: 'Beef Sate with Egg Noodles (Mi Bo Sa Te)',
            basePrice: 16.99,
            description: 'Egg noodle, lemongrass, chili, beef, and meatballs',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 25,
            name: 'Stir-Fried Noodles with Beef (Mi Xao Bo)',
            basePrice: 16.99,
            description: 'Stir-fried noodles with beef',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 26,
            name: 'Stir-Fried Noodles with Shrimp (Mi Xao Tom)',
            basePrice: 16.99,
            description: 'Stir-fried noodles with shrimp',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 27,
            name: 'Stir-Fried Noodles with Seafood (Mi Xao Do Bien)',
            basePrice: 18.99,
            description: 'Egg noodles, Squid, Shrimp, and Vegetables',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 28,
            name: 'Crab & Tomato Noodles (Bun Rieu)',
            basePrice: 16.99,
            description: 'Meatballs made of pork, shrimp, crab, and prawns',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        }
    ],
    rice: [
        {
            id: 29,
            name: 'Grilled Pork (Com Suon)',
            basePrice: 14.99,
            image: '/comsuon.jpeg',
            description: 'Grilled pork with rice',
            customizations: [
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Egg (1)', price: 1.50 },
                        { name: 'Rice Bowl (1)', price: 1.50 },
                        { name: 'Grilled Pork (1)', price: 6.99 },
                        { name: 'Grilled Chicken', price: 6.99 }
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 30,
            name: 'Combo Fried Rice (Com Chien Dac Biet)',
            basePrice: 17.99,
            description: 'Fried Rice with Beef, Chicken, and Shrimp',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 31,
            name: 'Combo Grilled Pork (Com Suon Bi Cha)',
            basePrice: 17.99,
            description: 'Grilled pork with rice and extra sides',
            customizations: [
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Egg (1)', price: 1.50 },
                        { name: 'Rice Bowl (1)', price: 1.50 },
                        { name: 'Grilled Pork (1)', price: 6.99 },
                        { name: 'Grilled Chicken', price: 6.99 }
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 32,
            name: 'Grilled Chicken (Com Ga Xoi Mo)',
            basePrice: 14.99,
            description: 'Grilled chicken with rice',
            customizations: [
                {
                    header: 'Add-ons',
                    type: 'multiple_select',
                    options: [
                        { name: 'Egg (1)', price: 1.50 },
                        { name: 'Rice Bowl (1)', price: 1.50 },
                        { name: 'Grilled Pork (1)', price: 6.99 },
                        { name: 'Grilled Chicken', price: 6.99 }
                    ]
                },
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 33,
            name: 'Chicken Fried Rice (Com Chien Ga)',
            basePrice: 15.99,
            description: 'Fried rice with chicken',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 34,
            name: 'Shrimp Fried Rice (Com Chien Tom)',
            basePrice: 15.99,
            description: 'Fried rice with shrimp',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 35,
            name: 'Vegan Fried Rice (Com Chien Chay)',
            basePrice: 15.99,
            description: 'Fried rice with mixed vegetables',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 36,
            name: 'Beef Fried Rice (Com Chien Bo)',
            basePrice: 15.99,
            description: 'Fried rice with beef',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        }
    ],
    sandwiches: [
        {
            id: 37,
            name: 'Grilled Pork Sandwich (Banh Mi Thit Nuong)',
            basePrice: 8.99,
            description: 'Vietnamese sandwich with grilled pork',
            image: '/banhmithitnuong.jpeg',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        },
        {
            id: 38,
            name: 'Pork Ham Sandwich (Banh Mi Cha)',
            basePrice: 8.99,
            description: 'Vietnamese sandwich with pork ham',
            customizations: [
                {
                    header: 'Special Instructions',
                    type: 'text_entry'
                }
            ]
        }
    ]
};
export default menuData;